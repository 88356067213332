import { gql } from '@apollo/client';
import debounce from 'lodash.debounce';
import { unreadMessages } from './AllClients.gql';
import * as log from '../logger';
/**
 * Acknowledge events on the server.
 */
export const ACK = gql `
  mutation Ack($ids: [ID!]!) {
    markSeenEvents(ids: $ids) {
      unread
    }
  }
`;
/**
 * Queue of (ClientId, MsgId) to acknowledge.
 */
const ackQueue = [];
/**
 * Acknowledge a batch of messages.
 */
const ackBatch = async (apolloClient) => {
    const unreads = unreadMessages();
    const ackedEventIds = [];
    while (ackQueue.length) {
        const [clientId, msgId] = ackQueue.pop();
        const clientUnreads = unreads.get(clientId);
        if (!clientUnreads) {
            // If the client has no unreads, just skip it.
            continue;
        }
        // Keep track of the messages that were actually newly acked.
        if (clientUnreads.has(msgId)) {
            ackedEventIds.push(clientUnreads.get(msgId));
            clientUnreads.delete(msgId);
        }
    }
    unreadMessages(new Map(unreads));
    if (!ackedEventIds.length) {
        return;
    }
    // Ack on the server side
    return apolloClient.mutate({
        mutation: ACK,
        variables: {
            ids: ackedEventIds,
        },
    });
};
/**
 * Debounced call to ackBatch.
 */
const debouncedAckBatch = debounce(ackBatch, 50);
/**
 * Mark message with given ID in given client ID thread as read.
 */
const ack = (apolloClient, clientId, msgId) => {
    ackQueue.push([clientId, msgId]);
    debouncedAckBatch(apolloClient);
};
/**
 * Check if an account is a staff account.
 */
const isStaffAccount = (account) => {
    if (!account) {
        return false;
    }
    if (!account.hasOwnProperty('__typename')) {
        return false;
    }
    return account.__typename === 'StaffAccount';
};
/**
 * Mark a message as read.
 */
export const ackMessage = (apolloClient, msg) => {
    const account = msg.source?.account;
    if (!account || !account.hasOwnProperty('__typename')) {
        return;
    }
    const clientId = isStaffAccount(account)
        ? msg.destination?.account?.id
        : msg.source?.account?.id;
    if (!clientId) {
        log.error(`Missing client ID from message ${msg.id}! can't ack it.`);
        return;
    }
    ack(apolloClient, clientId, msg.id);
};
