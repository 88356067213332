import { GET_MESSAGE } from '../query/GetMessage.gql';
import { updateMsgStatusFromPush } from '../cache/update/status';
import { unreadMessages } from '../query/AllClients.gql';
import * as log from '../logger';
/**
 * Get the full message from its ID.
 */
const getMessage = async (apolloClient, msgId) => {
    const { data, error } = await apolloClient.query({
        query: GET_MESSAGE,
        variables: { id: msgId },
    });
    if (!data || data.node?.__typename !== 'Message') {
        log.error(`Failed to get full message: ${error || 'unknown error'}`);
        return null;
    }
    return data.node;
};
/**
 * Track a new unread message.
 */
const trackMessage = (clientId, msgId, eventId = '') => {
    const unreads = unreadMessages();
    if (!clientId || !msgId) {
        log.error('Received message missing metadata!');
        return;
    }
    if (!unreads.has(clientId)) {
        unreads.set(clientId, new Map());
    }
    const clientUnreads = unreads.get(clientId);
    clientUnreads.set(msgId, eventId);
    unreadMessages(new Map(unreads));
};
/**
 * Handle an incoming push message.
 */
export const handleMessage = async (apolloClient, senderId, payload) => {
    if (!payload.data) {
        log.warn('Push message missing data');
        return;
    }
    const data = payload.data;
    switch (data.event_type) {
        case 'msg.receive':
            const receivedMsg = await getMessage(apolloClient, data.msg_id);
            trackMessage(receivedMsg?.source?.account?.id, receivedMsg?.id, data.event_id);
            return;
        case 'msg.send':
            const sentMsg = await getMessage(apolloClient, data.msg_id);
            const sentMsgSenderId = sentMsg?.source?.account?.id;
            if (sentMsgSenderId && senderId !== sentMsgSenderId) {
                trackMessage(sentMsg?.destination?.account?.id, sentMsg?.id, data.event_id);
            }
            return;
        case 'msg.status':
            return updateMsgStatusFromPush(apolloClient.cache, data);
        default:
            log.warn(`Unsure how to handle message: ${payload.data?.event_type}`);
    }
};
