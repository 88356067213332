import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { message } from 'antd';
import { refreshAccessToken } from './state/auth';
import { useAppStore } from './state/hooks';
import { Role } from './net/types/graphql';
import { configureApolloClient } from './net/apolloClient';
import { useBrowserId } from './BrowserId';
import * as log from './util/log';
import * as netLogger from './net/logger';
// Configure the net package to share the same logger as the rest of the app.
netLogger.setLogger(log);
/**
 * Some GraphQL errors are expected and should just be ignored.
 */
const suppressError = (e, operation) => {
    // NOTE: this error happens when the client information disappears from the
    // upstream organization CMS. It's fairly common, and it will result in the
    // UI missing information about this client. Don't annoy the user with banner
    // notifications about it, since there's nothing we can do.
    const wrongNumberOfClientsErr = /wrong number of clients/i;
    const noClientWithIdErr = /^No client with ID:/i;
    const noNativeIdErr = /no native ID registered/i;
    const noClientErr = /client not found/i;
    const noStaffEmailErr = /can't query staff without email/i;
    const clientNotFoundErr = /client not found/i;
    switch (operation.operationName) {
        case 'Chat':
            return clientNotFoundErr.test(e.message);
        case 'GetMessage':
            return (wrongNumberOfClientsErr.test(e.message) || noClientErr.test(e.message));
        case 'ClientInfo':
            return (noClientWithIdErr.test(e.message) ||
                noClientErr.test(e.message) ||
                noNativeIdErr.test(e.message));
        case 'AllClients':
            return (noClientWithIdErr.test(e.message) ||
                noClientErr.test(e.message) ||
                noNativeIdErr.test(e.message) ||
                wrongNumberOfClientsErr.test(e.message));
        case 'AllStaff':
            return noStaffEmailErr.test(e.message);
        default:
            return false;
    }
};
/**
 * Hook to get a configured Apollo Client.
 */
export const useConfiguredApolloClient = (store) => {
    const browserId = useBrowserId();
    return useMemo(() => configureApolloClient({
        getBaseUri() {
            const { baseUrl } = store.getState();
            return baseUrl.value;
        },
        getAuthTokenInfo() {
            const { auth } = store.getState();
            return {
                expiresAt: auth.expiresAt,
                accessToken: auth.token,
                accessTokenType: auth.tokenType,
                refreshToken: auth.refreshToken || undefined,
            };
        },
        async refreshAuthToken() {
            const { auth: { refreshToken }, } = store.getState();
            if (!refreshToken) {
                log.warn('No refresh token available');
                throw new Error('Logging you out');
            }
            const result = await store.dispatch(refreshAccessToken({
                refreshToken,
                deviceId: browserId,
            }));
            if (result.meta.requestStatus !== 'fulfilled') {
                throw new Error('Unable to keep you logged in');
            }
            return this.getAuthTokenInfo();
        },
        handleNetworkError(e) {
            log.warn('Network Error', e);
            message.error(`We're having issues communicating with the server: ${e.message}`);
        },
        handleAuthError(e) {
            log.error('Auth Error', e);
            message.error(`Your credentials are invalid: ${e.message}`);
            window.location.pathname = '/logout';
        },
        handleGraphqlError(e, operation) {
            const { auth } = store.getState();
            // Suppress all messages for regular users, since they are confusing.
            if (!auth.roles.includes(Role.Admin)) {
                log.warn('GraphQL Error:', e.message);
            }
            else if (!suppressError(e, operation)) {
                log.warn('GraphQL Error:', e.message);
                message.error(`An error occurred fetching data: ${e.message}`);
            }
            else if (__DEV__) {
                log.warn('[GraphQL Error] [SUPPRESSED]', e.message);
            }
            // This function handles every error, so just return true.
            return true;
        },
    }), [store]);
};
/**
 * Provide connectivity to the network via a configured Apollo client.
 */
export const NetworkProvider = ({ children }) => {
    const store = useAppStore();
    const apolloClient = useConfiguredApolloClient(store);
    return React.createElement(ApolloProvider, { client: apolloClient }, children);
};
