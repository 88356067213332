/**
 * Get a query param value.
 *
 * Returns two things:
 *  1) The value of the query parameter (might be undefined)
 *  2) Whether the key existed in the URL at all.
 */
export const getQueryParam = (k) => {
    if (typeof window === 'undefined') {
        return [undefined, false];
    }
    if (typeof URLSearchParams === 'undefined') {
        return [undefined, false];
    }
    const q = new URLSearchParams(window.location.search);
    return [q.get(k), q.has(k)];
};
/**
 * Check if the debug mode is enabled.
 *
 * In dev, debugging is always enabled. In prod it can be enabled with the
 * query parameter `debug`.
 */
export const isDebugging = () => {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }
    const [_, ok] = getQueryParam('debug');
    return ok;
};
