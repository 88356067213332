import React, { useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { message, Layout, Button, Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSafeNavigate as useNavigate } from './util/safeNavigate';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { updateLogin, reset as resetAuth, getCodeChallenge } from './state/auth';
import { reset as resetBaseUrl } from './state/baseUrl';
import { useAppConfig, APP_CONFIG } from './net/query/AppConfig.gql';
import { useLogin } from './net/query/Login.gql';
import { LoadingContainer } from './LoadingContainer';
const doLogin = async (client, codeVerifier) => {
    // Refetch the app config because we need a fresh state token, which will
    // expire after just a couple minutes. So if the browser sits idle for half
    // an hour they won't be able to log in.
    const [configResult] = await client.refetchQueries({
        include: [APP_CONFIG],
    });
    if (!configResult.data) {
        message.error("Can't log you in! " + configResult.error || 'unknown error');
        return;
    }
    const configData = configResult.data;
    const config = configData.clientConfig.oauth2;
    const url = new URL(config.authEndpoint);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', config.webClient.redirectUrl);
    url.searchParams.set('client_id', config.webClient.clientId);
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('state', config.state);
    url.searchParams.set('code_challenge', await getCodeChallenge(codeVerifier));
    url.searchParams.set('code_challenge_method', 'S256');
    if (config.accessType) {
        url.searchParams.set('access_type', config.accessType);
    }
    // Delegate to the OAuth2 provider
    window.location.href = url.toString();
};
export const Login = () => {
    const apolloClient = useApolloClient();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { codeVerifier, loggedIn } = useAppSelector(({ auth }) => auth);
    const baseUrl = useAppSelector(({ baseUrl }) => baseUrl.value);
    const { data, loading, error } = useAppConfig();
    const [login, lr] = useLogin();
    // Check the `from` search parameter
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const fromParam = query.get('from');
    // Check if the user is loading from an OAuth2 redirect. In this case,
    // we don't want to show the login button, just the loading spinner.
    const loadingFromOAuth2 = fromParam === 'oauth2';
    useEffect(() => {
        if (!baseUrl) {
            return navigate('/setup');
        }
        if (loading || error) {
            return;
        }
        if (!lr.loading && !lr.error && lr.data?.login) {
            dispatch(updateLogin(lr.data.login));
            // TODO(jnu): get redirect URI from state
            return navigate('/');
        }
        // If the login query hasn't been issued yet but the user appears to be
        // logged in (per local state), make the request to verify the auth info
        // with the server.
        if (loggedIn) {
            login();
            return;
        }
    }, [loading, error, baseUrl, loggedIn, lr.data, lr.loading, lr.error]);
    const logo = data?.clientConfig?.style?.login?.logo;
    return (React.createElement(LoadingContainer, { loading: loading || lr.loading || loadingFromOAuth2, error: error, data: data },
        React.createElement(Layout, { id: "nudge-root" },
            React.createElement(Layout.Content, { id: "nudge-body", className: "-centered" },
                React.createElement("div", null,
                    React.createElement(Row, { justify: "center", gutter: [16, 24] },
                        logo && (React.createElement(Col, { span: 24, className: "-centered" },
                            React.createElement("img", { className: "nudge-logo", src: logo }))),
                        React.createElement(Col, { className: "-centered", span: 24 },
                            React.createElement(Button, { className: "login", onClick: () => doLogin(apolloClient, codeVerifier), disabled: !data?.clientConfig?.oauth2 }, data?.clientConfig?.style?.login?.signInText || 'Login'),
                            React.createElement(Button, { className: "reset", onClick: () => {
                                    dispatch(resetAuth());
                                    dispatch(resetBaseUrl());
                                }, type: "dashed" }, "Reset"))))))));
};
