/**
 * Namespace containing events in this app.
 */
export const NAMESPACE = 'nudge';
/**
 * Events that are sent from UI thread to service worker.
 */
export var BgEventType;
(function (BgEventType) {
    BgEventType["Logout"] = "logout";
    BgEventType["Update"] = "update";
    BgEventType["RegisterPush"] = "registerPush";
    BgEventType["AccessToken"] = "accessToken";
})(BgEventType || (BgEventType = {}));
/**
 * Events that are sent from the service worker to the UI thread.
 */
export var FgEventType;
(function (FgEventType) {
    FgEventType["MsgReceive"] = "msg.receive";
    FgEventType["MsgStatus"] = "msg.status";
    FgEventType["MsgSend"] = "msg.send";
    FgEventType["SysLogout"] = "sys.logout";
})(FgEventType || (FgEventType = {}));
/**
 * Direction of posted message.
 */
export var Direction;
(function (Direction) {
    Direction["Fg"] = "fg";
    Direction["Bg"] = "bg";
})(Direction || (Direction = {}));
/**
 * Create a new message.
 */
const newMessage = (eventType, payload, direction) => ({
    ts: Date.now(),
    namespace: NAMESPACE,
    message: eventType,
    payload,
    direction,
});
/**
 * Creat a new background event.
 */
export const NewBgEvent = (eventType, payload) => newMessage(eventType, payload, Direction.Bg);
/**
 * Create a new foreground event.
 */
export const NewFgEvent = (eventType, payload) => newMessage(eventType, payload, Direction.Fg);
/**
 * Check if event is background event.
 */
export const isBgEvent = (evt) => evt?.direction === Direction.Bg;
/**
 * Check if event is foreground event.
 */
export const isFgEvent = (evt) => evt?.direction === Direction.Fg;
