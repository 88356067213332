import React, { useEffect, useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { GoogleTranslate } from './net/translate';
import { useAppConfig } from './net/query/AppConfig.gql';
/**
 * Context to store Google Translate API client.
 */
const GoogleTranslateApiContext = createContext(null);
/**
 * Get the Translate API client from the context.
 */
export const useGoogleTranslateApi = () => {
    return useContext(GoogleTranslateApiContext);
};
export const GoogleTranslateApiProvider = ({ children, }) => {
    const [gt, setGt] = useState(null);
    const { data: appConfig, error } = useAppConfig();
    const apiKey = appConfig?.clientConfig?.googleTranslateKey;
    useEffect(() => {
        setGt(apiKey ? new GoogleTranslate(apiKey) : null);
    }, [apiKey]);
    useEffect(() => {
        if (error) {
            message.error(`Error loading app config: ${error}`);
        }
    }, [error]);
    return (React.createElement(GoogleTranslateApiContext.Provider, { value: gt }, children));
};
export const useTranslation = ({ text, skip, into, source, }) => {
    const [translation, setTranslation] = useState(null);
    const [lang, setLang] = useState(null);
    const [loading, setLoading] = useState(false);
    const [canTranslate, setCanTranslate] = useState(false);
    const [error, setError] = useState(null);
    const api = useGoogleTranslateApi();
    useEffect(() => {
        setLoading(false);
        if (!api) {
            setCanTranslate(false);
            setLang(null);
            setTranslation(null);
            return;
        }
        setCanTranslate(true);
        if (skip) {
            return;
        }
        setLoading(true);
        setError(null);
        api
            .translate(text, into || 'en', source)
            .then((r) => {
            const translations = r.data.translations;
            if (translations.length === 0) {
                throw new Error('no translation found');
            }
            const t = translations[0];
            setTranslation(t.translatedText);
            setLang(t.detectedSourceLanguage);
            setCanTranslate(false);
        })
            .catch((e) => {
            setError(e);
        })
            .finally(() => {
            setLoading(false);
        });
    }, [api, text, skip, into, source]);
    return {
        translation,
        fromLang: lang,
        translating: loading,
        canTranslate,
        error,
    };
};
/**
 * Supported languages.
 *
 * These come from a list of the largest languages spoken in the Bay Area, plus
 * a list of languages that we have seen local requests for translators.
 *
 * We can add any language that Google supports, the full list is here:
 * https://cloud.google.com/translate/docs/languages
 *
 * Note that some relatively common languages that are requested are not
 * supported by Google, like Cantonese.
 */
export const Languages = [
    { code: 'en', name: 'English', alt: [] },
    { code: 'es', name: 'Spanish', alt: ['Spanish/Español'] },
    { code: 'vi', name: 'Vietnamese', alt: [] },
    { code: 'ru', name: 'Russian', alt: [] },
    { code: 'zh', name: 'Chinese', alt: ['Mandarin'] },
    { code: 'fr', name: 'French', alt: [] },
    { code: 'pl', name: 'Polish', alt: [] },
    { code: 'tl', name: 'Tagalog', alt: ['Filipino'] },
    { code: 'ja', name: 'Japanese', alt: [] },
    { code: 'ko', name: 'Korean', alt: [] },
    { code: 'de', name: 'German', alt: [] },
    { code: 'pt', name: 'Portuguese', alt: [] },
    { code: 'fa', name: 'Persian', alt: ['Farsi'] },
    { code: 'pa', name: 'Punjabi', alt: [] },
    { code: 'ro', name: 'Romanian', alt: [] },
].sort((a, b) => (a.name < b.name ? -1 : 1));
/**
 * Map to look up supported language codes by name.
 */
const langLookup = Languages.reduce((agg, cur) => {
    const names = [cur.name, ...cur.alt];
    for (const name of names) {
        agg[name.toLowerCase()] = cur.code;
    }
    return agg;
}, {});
/**
 * Map to look up supported language names by code.
 */
const codeLookup = Languages.reduce((agg, cur) => {
    agg[cur.code] = cur.name;
    return agg;
}, {});
/**
 * Try to get language code from language name.
 */
export const normLanguage = (lang) => {
    const needle = lang.toLowerCase();
    if (langLookup.hasOwnProperty(needle)) {
        return langLookup[needle];
    }
    return needle;
};
/**
 * Try to get the full name of a language by its code.
 */
export const lookupLanguageName = (code) => {
    const needle = code.toLowerCase();
    if (codeLookup.hasOwnProperty(needle)) {
        return codeLookup[needle];
    }
    return code;
};
