/**
 * Get the ID field from a normalized cached object or reference.
 */
export const getId = (opts, obj) => {
    // Note that there appears to be a bug that if the object hasn't been stored
    // in the cache yet, readField fails.
    let id = '';
    if (opts.readField) {
        id = opts.readField('id', obj) || '';
    }
    else if (obj.hasOwnProperty('id')) {
        id = obj.id || '';
    }
    if (!id) {
        const fullCacheId = opts.cache.identify(obj);
        id = fullCacheId?.split(':')[1] || '';
        if (!id) {
            throw new Error('Failed to get cache ID');
        }
    }
    return id;
};
